
import {
    ALL_NOTIFICATION
   } from "../../constant/ActionType";
   
   export default function AllDataClientsReduc(state = {
       AllNotification: [],
   
   }, action) {
       switch (action.type) {
           case ALL_NOTIFICATION:
               return {
                   ...state,
                   AllNotification: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }