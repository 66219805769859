// material-ui
// import { useMediaQuery, useTheme } from '@mui/material';

// project-imports
// import NavCard from './NavCard';
import Navigation from "./Navigation";
import SimpleBar from "components/third-party/SimpleBar";
// import { useSelector } from 'store';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  // const theme = useTheme();
  // const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  // const { drawerOpen } = useSelector((state) => state.menu);

  return (
    <SimpleBar
      sx={{
        backgroundColor: "#222222",
        "& .simplebar-content": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <>
        <Navigation />
      </>
    </SimpleBar>
  );
};

export default DrawerContent;
