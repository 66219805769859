
import {
    GET_ALL_DASHBOARD
   } from "../../constant/ActionType";
   
   export default function PropertyReducer(state = {
    propertygetreducerData: [],
   
   }, action) {
       switch (action.type) {
           case  GET_ALL_DASHBOARD:
               return {
                   ...state,
                   propertygetreducerData: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }