
import {
    GET_PROPERTY_PREMESIS
   } from "../../constant/ActionType";
   
   export default function GetAllFlat(state = {
       allFlatProperty: [],
   
   }, action) {
       switch (action.type) {
           case GET_PROPERTY_PREMESIS:
               return {
                   ...state,
                   allFlatProperty: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }