// material-ui
import { useTheme } from "@mui/material/styles";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logoIcon} alt="icon logo" width="100" />
     *
     */
    <svg
      width="60"
      height="60"
      viewBox="0 0 1052 1052"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_224_381)">
        <path
          d="M14 191.649C14 92.4316 94.4317 12 193.649 12H858.351C957.568 12 1038 92.4317 1038 191.649V856.351C1038 955.568 957.568 1036 858.351 1036H193.649C94.4316 1036 14 955.568 14 856.351V191.649Z"
          fill="#2D90DD"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_224_381"
          x="0"
          y="0"
          width="1052"
          height="1052"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="7" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_224_381"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_224_381"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default LogoIcon;
