import { UPDATE_ALL_CERTIFICATIONS } from "../../constant/ActionType";

export default function UpdateAllCertifications(
  state = {
    UpdateCertifications: [],
  },
  action
) {
  switch (action.type) {
    case UPDATE_ALL_CERTIFICATIONS:
      return {
        ...state,
        UpdateCertifications: action?.data?.data,
      };

    default:
  }

  return state;
}
