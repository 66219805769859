import {
    VIEW_PRODUCTS
} from "../constant/ActionType";

export default function AllProducts(state = {
    ViewProductz: [],
    lastPage: 1,
}, action) {
    switch (action.type) {
        case VIEW_PRODUCTS:
            return {
                ...state,
                ViewProductz: action?.data?.data?.data,
                lastPage: action?.data?.data?.last_page
            };

        default:
    }

    return state;
}