import {
    CREATE_ROLE
} from "../constant/ActionType";

export default function CreateRolssseeeReducer(state = {
    registerRolsss: [],
    statusCode: null,
}, action) {
    switch (action.type) {
        case CREATE_ROLE:
            return {
                ...state,
                registerRolsss: action.data,
                    statusCode: action.status
            };

        default:
    }

    return state;
}