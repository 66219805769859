
import {
    GET_ALL_PROPERTY
   } from "../../constant/ActionType";
   
   export default function GetAllProperty(state = {
       AllPropertyData: [],
   
   }, action) {
       switch (action.type) {
           case GET_ALL_PROPERTY:
               return {
                   ...state,
                   AllPropertyData: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }