
import {
    CLIENT_ALL_VIEW
   } from "../../constant/ActionType";
   
   export default function AllDataClientsReduc(state = {
       AllClientsData: [],
   
   }, action) {
       switch (action.type) {
           case CLIENT_ALL_VIEW:
               return {
                   ...state,
                   AllClientsData: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }