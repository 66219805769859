import {
    REGISTER_BRAND_OWNER
} from "../constant/ActionType";

export default function RegisterOwnerBrandReducer(state = {
    brandonrregisete: [],
    statusCode: null,
}, action) {
    switch (action.type) {
        case REGISTER_BRAND_OWNER:
            return {
                ...state,
                brandonrregisete: action.data,
                    statusCode: action.status
            };

        default:
    }

    return state;
}