import {
    ALL_ASSIGN_PREMISES_DETAILS
   } from "../../constant/ActionType";
   
   export default function AllAssignPreDetails(state = {
       allDetails: [],
   
   }, action) {
       switch (action.type) {
           case ALL_ASSIGN_PREMISES_DETAILS:
               return {
                   ...state,
                   allDetails: action?.data?.data,
               };
   
           default:
       }

       return state;
   }