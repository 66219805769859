
import {
    REPORT_BY_PAYMENT
   } from "../../constant/ActionType";
   
   export default function ReportByPayReducerrr(state = {
       reportbyPaym: [],
   
   }, action) {
       switch (action.type) {
           case REPORT_BY_PAYMENT:
               return {
                   ...state,
                   reportbyPaym: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }