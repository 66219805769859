// type
import { ShopRemove } from 'iconsax-react';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";
import { MdHome } from "react-icons/md";
import { BsBuildingFillAdd } from "react-icons/bs";
import { TbRulerMeasure } from "react-icons/tb";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { TbHomeCog } from "react-icons/tb";
import { MdOutlineHomeWork } from "react-icons/md";
import { can_add_property,can_edit_property,can_view_property } from "../helper/permissionsCheck";

// icons
const icons = {
    navigation: MdHome,
    propertyType: TbHomeCog,
    amenities:BsBuildingFillAdd,
    flatSize:TbRulerMeasure,
    taxes:HiOutlineReceiptTax,
    property:MdOutlineHomeWork,
    color: MdHome,
    shadow: MdHome
};

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem('data');

  let decryptedData = null;
  if(permissionUser){
      try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(enc.Utf8);
    // ...
} catch (error) {
  console.log(error,"er")
}
  }
  
  const permissions =decryptedData?JSON.parse(decryptedData)?.permissions:null;

const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map(permission => permission?.permission) : [];

const permissionsAccessFunction = (brand) => {
  return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions.some(permission => permission.includes(brand));
}
// ==============================|| MENU ITEMS - roles ||============================== //

// const property = permissionsAccessFunction('company') ? {
const property = (can_add_property||can_edit_property||can_view_property ) ? {
    id: 'property',
    title: 'Property',
    icon: icons.navigation,
    type: 'group',
    children: [

        // allowedPermissions.includes('get-company') ? {
      
       
       true ? {
            id: 'all-property',
            title: 'Property',
            type: 'item',
            url: '/property/all-property',
            icon: icons.property,
            breadcrumbs: true
        } : null,
       

    ].filter(Boolean)
} : null;

export default property;
