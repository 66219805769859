
import {
    COMPANY_DETAIL_ONE_USER
} from "../constant/ActionType";

export default function CompanyDetailingReducer(state = {
    companiesoneinformation: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case COMPANY_DETAIL_ONE_USER:
            return {
                ...state,
                companiesoneinformation: action?.data?.data,
            };

        default:
    }

    return state;
}