
import {
    GET_ALL_FEEDBACKS
} from "../../constant/ActionType";

export default function AllFeedbackreducers(state = {
    allfeedback: [],

}, action) {
    switch (action.type) {
        case GET_ALL_FEEDBACKS: 
            return {
                ...state,
                allfeedback: action?.data?.data,
                
            };

        default:
    }

    return state;
}