import {
    GET_ALL_NOTIFICATION
} from "../constant/ActionType";

export default function Notificationgetall(state = {
    notificationData: [],
}, action) {
    switch (action.type) {
        case GET_ALL_NOTIFICATION:
            return {
                ...state,
                notificationData: action.data,
            };

        default:
    }

    return state;
}
