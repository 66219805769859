import {
    ALL_ASSIGN_PERMISSIONS
   } from "../../constant/ActionType";
   
   export default function AllGetAssignPer(state = {
       allPermissionss: [],
   
   }, action) {
       switch (action.type) {
           case ALL_ASSIGN_PERMISSIONS:
               return {
                   ...state,
                   allPermissionss: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }