
import {
    GET_ALL_ACTIVE_PROPERTY
   } from "../../constant/ActionType";
   
   export default function GetAllAmenities(state = {
       AllActivePropertyData: [],
   
   }, action) {
       switch (action.type) {
           case GET_ALL_ACTIVE_PROPERTY:
               return {
                   ...state,
                   AllActivePropertyData: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }