import { EXPORT_CSV_CATEGORYIES } from "../constant/ActionType";

export default function CSVCategories(
  state = {
    csvexportcategory: [],
    statuscode: null,
  },
  action
) {
  switch (action.type) {
    case EXPORT_CSV_CATEGORYIES:
      return {
        ...state,
        csvexportcategory: action?.data?.data,
      };

    default:
  }

  return state;
}
