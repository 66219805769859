
import {
    GET_ALL_PROPERTY_TYPE
   } from "../../constant/ActionType";
   
   export default function getAllDashboard(state = {
       AllPropertyTypeData: [],
   
   }, action) {
       switch (action.type) {
           case GET_ALL_PROPERTY_TYPE:
               return {
                   ...state,
                   AllPropertyTypeData: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }