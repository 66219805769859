// type
import { ShopRemove } from "iconsax-react";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
import { MdHome } from "react-icons/md";
import { BsBuildingFillAdd } from "react-icons/bs";
import { TbRulerMeasure } from "react-icons/tb";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { TbHomeCog } from "react-icons/tb";
import { MdOutlineHomeWork } from "react-icons/md";
import { MdOutlineBedroomParent } from "react-icons/md";
import {
  can_add_assign_premises,
  can_edit_assign_premises,
  can_view_assign_premises,
  can_add_flat,
  can_edit_flat,
  can_view_flat,
} from "helper/permissionsCheck";
// icons
const icons = {
  navigation: MdHome,
  flatType: TbHomeCog,
  amenities: BsBuildingFillAdd,
  flatSize: TbRulerMeasure,
  taxes: HiOutlineReceiptTax,
  flat: MdOutlineBedroomParent,
  color: MdHome,
  shadow: MdHome,
};
// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    console.log(error, "er");
  }
}

const permissions = decryptedData
  ? JSON.parse(decryptedData)?.permissions
  : null;

const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];

const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};
// ==============================|| MENU ITEMS - roles ||============================== //

// const flat = permissionsAccessFunction('company') ? {
const flat =
  can_add_flat ||
  can_edit_flat ||
  can_view_flat ||
  can_add_assign_premises ||
  can_edit_assign_premises ||
  can_view_assign_premises
    ? {
        id: "flat",
        title: "Flats / Shops ",
        icon: icons.navigation,
        type: "group",
        children: [
          // allowedPermissions.includes('get-company') ? {

          can_add_flat || can_edit_flat || can_view_flat
            ? {
                id: "all-flat",
                title: "Flats/Shops",
                type: "item",
                url: "/flat/all-flat",
                icon: icons.flat,
                breadcrumbs: true,
              }
            : null,

          can_add_assign_premises ||
          can_edit_assign_premises ||
          can_view_assign_premises
            ? {
                id: "all-assign-permissions",
                title: "All Assign Flat / Shops",
                type: "item",
                url: "/premises/all-assign-premises",
                icon: icons.flat,
                breadcrumbs: true,
              }
            : null,
        ].filter(Boolean),
      }
    : null;

export default flat;
