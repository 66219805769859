
import {
    ALL_REPORTS
   } from "../../constant/ActionType";
   
   export default function ReportAllGetREduc(state = {
       Allreportss: [],
   
   }, action) {
       switch (action.type) {
           case ALL_REPORTS:
               return {
                   ...state,
                   Allreportss: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }