// type
import { ShopRemove } from "iconsax-react";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
import { MdHome } from "react-icons/md";
import { BsBuildingFillAdd } from "react-icons/bs";
import { TbRulerMeasure } from "react-icons/tb";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { TbHomeCog } from "react-icons/tb";
import { MdOutlineHomeWork } from "react-icons/md";
import { MdOutlineBedroomParent } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { IoKeySharp } from "react-icons/io5";
import { TbReportSearch } from "react-icons/tb";
import {
  can_add_permissions,
  can_edit_permissions,
  can_view_permissions,
} from "helper/permissionsCheck";
// icons
const icons = {
  navigation: MdHome,
  flatType: TbHomeCog,
  amenities: BsBuildingFillAdd,
  flatSize: TbRulerMeasure,
  taxes: HiOutlineReceiptTax,
  flat: MdOutlineBedroomParent,
  assignPermission: MdOutlineSecurity,
  color: MdHome,
  shadow: MdHome,
  permission: IoKeySharp,
  Reports: TbReportSearch,
};
// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    console.log(error, "er");
  }
}

const permissions = decryptedData
  ? JSON.parse(decryptedData)?.permissions
  : null;

const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];

const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};
// ==============================|| MENU ITEMS - roles ||============================== //

// const flat = permissionsAccessFunction('company') ? {
const reports = true
  ? {
      id: "reports",
      title: "Reports",
      icon: icons.Reports,
      type: "group",
      children: [
        // allowedPermissions.includes('get-company') ? {

        true
          ? {
              id: "all-reports",
              title: "All Reports",
              type: "item",
              url: "/reports/all-reports",
              icon: icons.Reports,
              breadcrumbs: true,
            }
          : null,

        true
          ? {
              id: "report-by-client",
              title: "Reports By Clients",
              type: "item",
              url: "/reports/report-by-client",
              icon: icons.Reports,
              breadcrumbs: true,
            }
          : null,

        true
          ? {
              id: "report-by-payment",
              title: "Reports By Payments",
              type: "item",
              url: "/reports/report-by-payment",
              icon: icons.Reports,
              breadcrumbs: true,
            }
          : null,
        true
          ? {
              id: "report-by-month",
              title: "Reports By Months",
              type: "item",
              url: "/reports/report-by-month",
              icon: icons.Reports,
              breadcrumbs: true,
            }
          : null,
        true
          ? {
              id: "report-by-flats-shops",
              title: "New Requests",
              type: "item",
              url: "/reports/report-by-flats-shops",
              icon: icons.Reports,
              breadcrumbs: true,
            }
          : null,
        true
          ? {
              id: "report-by-property",
              title: "Reports By Property",
              type: "item",
              url: "/reports/report-by-property",
              icon: icons.Reports,
              breadcrumbs: true,
            }
          : null,
      ].filter(Boolean),
    }
  : null;

export default reports;
