
import {
    REPORT_BY_MONTH
   } from "../../constant/ActionType";
   
   export default function ReportByMonthReducc(state = {
       reportMothdata: [],
   
   }, action) {
       switch (action.type) {
           case REPORT_BY_MONTH:
               return {
                   ...state,
                   reportMothdata: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }