
import {
    ALL_ACTIVE_MAINTENANCE_TYPE
   } from "../../constant/ActionType";
   
   export default function AllActiveMaintenanceType(state = {
    allActiveMaintenanceData: [],
   
   }, action) {
       switch (action.type) {
           case ALL_ACTIVE_MAINTENANCE_TYPE:
               return {
                   ...state,
                   allActiveMaintenanceData: action?.data,
               };
   
           default:
       }
   
       return state;
   }