import {
    PREMISES_FILTERS
} from "../constant/ActionType";

export default function PremisesFIlterData(state = {
    getfilterPremises: [],
}, action) {
    switch (action.type) {
        case PREMISES_FILTERS:
            return {
                ...state,
                getfilterPremises: action.data,
            };

        default:
    }

    return state;
}