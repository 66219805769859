
import {
    CLIENT_ALL_DETAILS
   } from "../../constant/ActionType";
   
   export default function AllClientsDetials(state = {
       AllClientsDetails: [],
   
   }, action) {
       switch (action.type) {
           case CLIENT_ALL_DETAILS:
               return {
                   ...state,
                   AllClientsDetails: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }