import {
    GET_ALL_ACTIVE_PROPERTY_TYPE
   } from "../../constant/ActionType";
   
   export default function getAllDashboard(state = {
       AllActivePropertyTypeData: [],
   
   }, action) {
       switch (action.type) {
           case GET_ALL_ACTIVE_PROPERTY_TYPE:
               return {
                   ...state,
                   AllActivePropertyTypeData: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }