
import {
    GET_PAYMENT
   } from "../../constant/ActionType";
   
   export default function GetAllPayments(state = {
    GetAllPaymentsData: [],
   
   }, action) {
       switch (action.type) {
           case GET_PAYMENT:
               return {
                   ...state,
                   GetAllPaymentsData: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }