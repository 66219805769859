import {
    BRAND_ONE_DETAIL
} from "../constant/ActionType";

export default function BrandDetailsGetDataReducer(state = {
    brandetail: [],
    statusCode: null,
}, action) {
    switch (action.type) {
        case BRAND_ONE_DETAIL:
            return {
                ...state,
                brandetail: action.data,
                    statusCode: action.status
            };

        default:
    }

    return state;
}