import { CERTIFICATE_STATUS } from "../../constant/ActionType";

export default function GetAllStatus(
  state = {
    AllStatus: [],
  },
  action
) {
  switch (action.type) {
    case CERTIFICATE_STATUS:
      return {
        ...state,
        AllStatus: action?.data?.data,
      };

    default:
  }

  return state;
}
