
import {
    REPORTS_BY_FLATS_SHOPS
   } from "../../constant/ActionType";
   
   export default function ReportByFaltsShoppss(state = {
       reportByyflatshops: [],
   
   }, action) {
       switch (action.type) {
           case REPORTS_BY_FLATS_SHOPS:
               return {
                   ...state,
                   reportByyflatshops: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }