
import {
    GET_ALL_TAXES
   } from "../../constant/ActionType";
   
   export default function GetAllPropertyType(state = {
       AllTaxesData: [],
   
   }, action) {
       switch (action.type) {
           case GET_ALL_TAXES:
               return {
                   ...state,
                   AllTaxesData: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }