
import {
    REPORTS_BY_PROPERTY
   } from "../../constant/ActionType";
   
   export default function ReportbyPropertyy(state = {
       reportbypropertiess: [],
   
   }, action) {
       switch (action.type) {
           case REPORTS_BY_PROPERTY:
               return {
                   ...state,
                   reportbypropertiess: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }