
import {
    GET_ALL_ACTIVE_TAX
   } from "../../constant/ActionType";
   
   export default function GetAllActiveTax(state = {
       AllActiveTaxesData: [],
   
   }, action) {
       switch (action.type) {
           case GET_ALL_ACTIVE_TAX:
               return {
                   ...state,
                   AllActiveTaxesData: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }