import {
    VIEW_USERS
} from "../constant/ActionType";

export default function ViewAllRedUsers(state = {
    ViewUsers: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case VIEW_USERS:
            return {
                ...state,
                ViewUsers: action?.data?.data?.data,
                lastPage: action?.data?.data?.last_page
            };

        default:
    }

    return state;
}