
import {
    GETALL_CATEGEORY
} from "../constant/ActionType";

export default function AllGetCateeReducers(state = {
    allcategoeries: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case GETALL_CATEGEORY:
            return {
                ...state,
                allcategoeries: action?.data?.data?.data,
                lastPage: action?.data?.data?.last_page
            };

        default:
    }

    return state;
}