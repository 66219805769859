
import {
    REPORT_BY_CLIENT
   } from "../../constant/ActionType";
   
   export default function ReportByyClieReducer(state = {
       clientreports: [],
   
   }, action) {
       switch (action.type) {
           case REPORT_BY_CLIENT:
               return {
                   ...state,
                   clientreports: action?.data?.data,
               };
   
           default:
       }
   
       return state;
   }