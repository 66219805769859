import { EXPORT_CSV_COMPANY } from "../constant/ActionType";

export default function CSvCompanyReducer(
  state = {
    csvvCompany: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case EXPORT_CSV_COMPANY:
      return {
        ...state,
        csvvCompany: action.data,
        statusCode: action.status,
      };

    default:
  }

  return state;
}
