import {
    VIEW_ACTIVE_PRODUCTS
} from "../constant/ActionType";

export default function AllActiveProducts(state = {
    ViewActiveProduct: [],
}, action) {
    switch (action.type) {
        case VIEW_ACTIVE_PRODUCTS:
            return {
                ...state,
                ViewActiveProduct: action?.data?.data,
            };

        default:
    }

    return state;
}