
import {
    EXPORT_CSV
} from "../constant/ActionType";

export default function ExportReducerCsv(state = {
    exportcsv: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case EXPORT_CSV:
            return {
                ...state,
                exportcsv: action?.data?.data,
            };

        default:
    }

    return state;
}